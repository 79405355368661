* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


body {
    font-family: 'FarroLight', sans-serif;
    background-color: #1c3144;
    min-height: 100vh;
}


.snap-segment {
    /* height: 100vh; */
    scroll-snap-align: center;
    margin: 0rem 0rem 1rem 0rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

section {
    /* height: 100vh; */
    scroll-snap-align: center;
    margin: 1rem 0rem 1rem 0rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intro-container {
    /* position: relative; */
    margin-top: 2rem;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.intro-content {
    position: absolute;
    top: 33rem;
    z-index: 2;
    color: black;
    text-align: center;
    padding: 1.25rem 1.25rem 12.5rem 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 55rem;
}

.introduction-description {
    font-weight: bold;
    font-size: 3.5rem;
}

.introduction-description h1 {
    padding-top: 0.5rem;
    color: white;
}

.introduction-description p {
    font-size: 2rem;
    color: white;
    /* width: 100%; */
    /* width: clamp(min(1023px, 100vw), 1920px, 100vw); */
    margin-left: 30rem;
    margin-right: 30rem;
    padding-bottom: 1rem;
}

.image-element {
    height: 20.13rem;
    width: 20.13rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    border-radius: 3rem;
    align-self: center;
}

.to-top-btn {
    z-index: 10;
}

/** For About Me Section */
.grid {
    margin-top: 2.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
    height: auto;
    /* Allowed text to all align properly for children /h2/p tags */
    align-items: start;
}

.about-me-section {
    padding: 5rem 2rem 5rem 2rem;
    margin: 5rem 2rem 5rem 2rem;
    background-color: #ffd400;
    border-radius: 2.5rem;
    font-size: 1.5rem;
    text-align: center;
    flex-wrap: wrap;
    justify-content: space-around;
}

.spacing-for-about-section {
    margin-top: 0rem;
}

.stat-container {
    display: flex;
    justify-content: center;
    gap: 10rem;
}

.span-title {
    font-size: 1.5rem;
}

.exp-container, .project-exp-container{
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
}

.about-me-section h1 {
    font-family: 'FarroBold';
    font-size: 3.2rem;
}

.about-me-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.7rem;
    gap: 1rem;
    /* Block element is ease of fixating items into a container, but adjusting grid align-items is better for responsive app */
    /* display:block; */
    /* position: relative;
    overflow:hidden; */
}

.about-me-page img {
    height: auto;
    border-radius: 20px;
}

.about-me-page h2 {
    font-family: 'FarroBold';
    font-size: 2rem;
}

.about-me-page p {
    font-size: 1rem;
    padding: 0 1rem;
    text-align: center;
}

.about-me-page a {
    pointer-events: none;
}


/* Responsiveness */

@media (max-width: 26.875em) {
    .grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1%;
        height: auto;
    }
}


/* Only adjusting differences between OG and media query */
@media screen and (max-width: 1670px) and (min-width: 1023px) {

    .introduction-description p {
        font-size: 1.5rem;
        margin-left: 10rem;
        margin-right: 10rem;
    }

    .intro-content {
        position: absolute;
        top: 30rem;
        font-size: 2rem;
        z-index: 2;
        bottom: 48rem;
    }
}

@media screen and (max-width: 480px) and (min-width: 340px) {

    .about-me-section {
        padding: 5rem 0rem 5rem 0rem;
        margin: 5rem 0rem 5rem 0rem;
        background-color: #ffd400;
        border-radius: 2.5rem;
        font-size: 1.5rem;
        text-align: center;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .intro-container {
        /* position: relative; */
        height: 80vh;
        width: 100%;
        /* overflow: hidden; */
    }

    .intro-content {
        /* position: relative; */
        /* Keep Absolute pos removed from normal doc flow , positioned relative to closest positioned ancestor, changeable by top/bot etc.. */
        position: absolute;
        top: 23rem;
        font-size: 2rem;
        z-index: 2;
        bottom: 0rem;
    }

    .inner-container {
        margin-bottom: 0rem !important;
        /* margin-right: 1rem; */
    }

    .introduction-description h1 {
        font-size: 2rem;
        padding-top: 0.5rem;
    }

    .introduction-description p {
        font-size: 1.5rem;
        margin-left: 0;
        margin-right: 0;
    }

    /* Adjust scroll to top btn more */
}