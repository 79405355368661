:root, [data-theme] {
    /* --color-primary: 37, 99, 235;
    --color-secondary: 253, 224, 71; */
    color: black !important;
    /* color: hsla(var(--bc) / var(--tw-text-opacity, 1)) !important; */
};

.TopNavHeader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #9eb7e5;
    padding: 2rem 9%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1.5s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
  }

/* complete override of margin bottom from tailwind */
.mb-20 {
    margin-bottom: 0px !important;
}

@font-face {
    font-family: 'FarroBold';
    src: url('/fonts/Farro-Bold.otf');
}

@font-face {
    font-family: 'FarroLight';
    src: url('/fonts/Farro-Light.otf');
}

@font-face {
    font-family: 'FarroMedium';
    src: url('/fonts/Farro-Medium.otf');
}

@font-face {
    font-family: 'FarroRegular';
    src: url('/fonts/Farro-Regular.otf');
}

@tailwind base;
@tailwind components;
@tailwind utilities;