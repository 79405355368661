.footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 2rem 5rem 0.5rem 5rem;
    border-block-start: 0.1rem solid rgb(255,255,255, 0.3); 
}

.footer-container > footer {
    padding: 1rem;
}

#copyright {
    padding: 0.5rem 0 0 0;
    text-align: center;
}

.social-media-icons {
    display: flex;
    padding: 0.5rem;
    justify-content: space-evenly;
}