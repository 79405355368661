* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'farroLight';
    background-color: #1c3144;
    min-height: 100vh;
}


main {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projects-subheader {
    font-family: 'FarroBold';
    font-size: 3.2rem;
    text-align: center;
}

.projects-subheader h1 {
    margin-top: 6rem;
    padding-top: 2rem;
    color: white;
    padding-bottom: 3rem;
}

/* Container for prevent images from touching edge of app. */
.container {
    width: 90vw;
    max-width: 90vw;
    margin: 0 auto;
    height: 90%;
}

/* Grid to allow for 3 split images across 1 row Main Container*/
.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-size: cover;
    gap: 2rem;
    height: auto;
    align-items: stretch;
}

.card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 1.25rem;
    background: linear-gradient(135deg, rgba(255, 212, 0, 0.9), rgba(255, 140, 0, 0.9));
    border-radius: 2rem;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: all linear 0.2s;
}

.card:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition-duration: 0.2s;
    border-color: rgb(255, 234, 128);
    transform: scale(1.05);
}

.card-img img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    transition: transform 0.3s ease;
}

/* .card-img img:hover {
    transform: scale(1.1);
} */

.card-body {
    flex-grow: 1;
    margin: 0.5rem 0rem 0rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.94em 1.25em;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 1.5rem;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
}

.card-title {
    font-weight: 700;
    font-size: 21px;
    padding-bottom: 10px;
    width: 100%;
    color: black;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.card-body p {
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 10px;
    color: #333;
}

.fixed-text {
    padding: 0.5rem;
    height: 8rem;
    width: auto;
    color: #666;
}

.card .button {
    padding: 0.75rem 1.5rem;
    background-color: #ff8c00;
    color: #ffffff;
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.card .button:hover {
    background-color: #ffd400;
    color: #333333;
}


a.view-project {
    background-color: #312f3a;
    color: #f8f8f8;
    text-decoration: none;
    font-size: 15px;
    letter-spacing: 1.1px;
    padding: 0.2rem 1rem;
    display: inline-block;
    /* margin-top: auto;  */
    margin: 0rem 0rem 0.5rem 0rem;
    border-radius: 0.5rem;
    transition: 0.3s;
    /* margin: auto; Center the button horizontally */
}

a.view-project:hover {
    background-color: #999999;
}

.title-and-github-btn {
    display: flex;
    justify-content: space-between;
}


/* responsive */
@media only screen and (max-width: 1670px) and (min-width: 1025px) {
    section {
        margin: 4rem 0rem 4rem 0rem;
    }

    .container {
        max-width: 90%;
    }

    .card {
        padding: 1rem;
        background: linear-gradient(135deg, #ffd400, #ff8c00);
    }

    .card-body {
        padding: 1rem;
    }

    .card-title {
        font-size: 24px;
        padding-bottom: 14px;
    }

    .card-body p {
        font-size: 18px;
    }

    .title-and-github-btn {
        display: flex;
        justify-content: space-between;
        align-items: center
    }

    a.view-project {
        padding: 0.5rem 1rem;
        width: auto;
        height: auto;
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 1024px) {
    section {
        margin: 4rem 0rem 4rem 0rem;
    }

    .grid {
        grid-template-columns: 1fr;
    }

    .card {
        height: 38rem;
        background: linear-gradient(135deg, #ffd400, #ff8c00);
    }

    .title-and-github-btn {
        display: flex;
        justify-content: space-between;
        align-items: center
    }
    
    a.view-project {
        padding: 0.5rem 1rem;
        width: auto;
        height: auto;
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 480px) and (min-width: 360px) {
    section {
        margin-top: 0rem;
    }

    .card {
        display: flex;
        flex-direction: column;
        /* background: linear-gradient(#ffd400, #ffd400); */
        background: linear-gradient(135deg, #ffd400, #ff8c00);
        border-radius: 2rem;
        text-align: center;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        transition: all linear 0.2s;
        height: 34rem;
    }

    .container {
        max-width: 90%;
    }

    .card-body {
        padding: 0.5rem;
        margin-bottom: 10px;
    }

    .card-title {
        font-size: 20px;
        padding-bottom: 0.5rem;
    }

    .title-and-github-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .image-mod {
        display: flex;
        justify-content: center;   
    }
    .description-mod {
        display: flex;
        text-align: center;
    }
    a.view-project {
        width: 50%;
        /* margin-top: 1.25rem; */
    }
}


/* ** New Style */