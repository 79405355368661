.technologies-section {
    padding: 10rem 39rem 10rem 39rem;
    margin: 5rem 0rem 7.5rem 0rem;
    color: white;
    background-color: rgba(2, 48, 71, 0.4);
    backdrop-filter: blur(1rem);
    border-radius: 2.5rem;
    z-index: 0;
    text-align: center;
}

.technologies-section h1 {
    font-family: 'FarroBold';
    font-size: 3.2rem;
    padding-bottom: 3rem;
}

.icon-grid-container {
    display: grid;
    grid-template-columns: repeat(5, fit-content(80%));
    grid-gap: 2rem;
    justify-items: center;
    justify-content: center;
}


.icon-size {
    background-color: rgb(255, 255, 255, 0.3);
    border: solid;
    border-color: rgba(0, 0, 0, 0.2);
    padding: 0.5rem;
    border-radius: 0.2rem;
    width: 5rem;
    height: 5rem;
}

.snap-segment{
    scroll-snap-align: center;
}


.skills-and-technologies {
    /* margin: 5rem 2rem 0rem 2rem; */
    /* background-color: #ffd400; */
    border-radius: 2.5rem;
}

@media screen and (max-width: 1670px) and (min-width: 1023px) {

    .technologies-section {
        padding: 10rem 26rem 10rem 26rem;
    }
    .skills-and-technologies {
        margin: 2.5rem 2rem 2.5rem 2rem;
        /* background-color: #ffd400; */
        border-radius: 2.5rem;
    }
    .icon-grid-container {
        display: grid;
        grid-template-columns: repeat(5, fit-content(80%));
        grid-gap: 2rem;
        justify-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 480px) and (min-width: 360px) {

    .skills-and-technologies {
        margin: 0rem 0.5rem 0rem 0.5rem;
        /* background-color: #ffd400; */
        border-radius: 2.5rem;
    }
    .technologies-section {
        padding: 1rem;
        margin: 3rem 0rem 3.25rem 0rem;
        color: white;
        background-color: rgba(2, 48, 71, 0.4);
        backdrop-filter: blur(1rem);
        border-radius: 2.5rem;
        z-index: 0;
        text-align: center;
    }

    .icon-grid-container {
        display: grid;
        grid-template-columns: repeat(3, fit-content(80%));
        grid-gap: 2rem;
        justify-items: center;
        justify-content: center;
    }

}